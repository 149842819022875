import React from "react"
import { H1 } from "../components/text/H1"
import Layout from "../components/Layout"
import SplitView from "../components/SplitView"
import AppEntwicklungIllustration from "../images/app_entwicklung.svg"
import JonasImage from "../images/jonas_herrmannsdoerfer.jpg"
import JonasImageRound from "../images/potrait_jonas_herrmannsdoerfer_round.png"
import BandarImageRound from "../images/bandar_shanneik_potrait.png"
import MichaelImageRound from "../images/michael_jost_potrait.png"
import TherapeutInnenRound from "../images/psychotherapeut_innen_potrait.png"
import SplitViewImage from "../components/SplitViewImage"
import { LinkType, OutsideLink } from "../components/text/OutsideLink"
import Section from "../components/Section"
import { H2 } from "../components/text/H2"
import ThreeColumns from "../components/ThreeColumns"

export interface UeberUnsProps {}

const teamInput = [
  {
    illustration: {
      src: JonasImageRound,
      alt: "Potrait Jonas Herrmannsdörfer",
      noSVG: true,
    },
    heading: "Jonas Herrmannsdörfer",
    text: "Gründer & Geschäftsführer",
  },
  {
    illustration: {
      src: BandarImageRound,
      alt: "Potrait Bandar Shanneik",
      noSVG: true,
    },
    heading: "Bandar Shanneik",
    text: "Rechtsberatung",
  },
  {
    illustration: {
      src: MichaelImageRound,
      alt: "Potrait Michael Jost",
      noSVG: true,
    },
    heading: "Michael Jost",
    text: "Datenschutzexperte",
  },
  {
    illustration: {
      src: TherapeutInnenRound,
      alt: "Potrait Psychotherapeut*innen",
      noSVG: true,
    },
    heading: "Psychotherapeut*innen",
    text: "Inhaltliche Beratung durch Feedback",
  },
]

const UeberUns: React.SFC<UeberUnsProps> = () => {
  return (
    <Layout>
      <SplitView
        elementLeft={
          <>
            <H1 text="Wer steht hinter der Idee für die Psychotherapie App?" />
            <p className="text-gray-400 leading-relaxed text-left">
              Die Idee zur App wurde Anfang 2020 im Zuge eines Seminars zum
              Thema Innovationsmanagement und Gründen an der FH
              Würzburg-Schweinfurt entwickelt. Grundsätzlich ging es um die
              Frage wie man Prozesse in Zeiten der Digitalisierung optimieren
              kann. Die beginnende Coronakrise lenkte das Augenmerk schnell auf
              das Thema psychische Gesundheit. Soziale Isolation, Angst vor der
              Infektion mit Covid-19 oder auch die Sorge um den eigenen
              Arbeitsplatz stellen zusätzliche ernst zu nehmende Stressoren dar.
              Welche Auswirkungen dies langfristig auf die ohnehin schon
              angespannte Versorgungssituation von psychisch Kranken hat, ist
              noch nicht absehbar. Daher entstand die Vision
              Psychotherapeut*innen und Patient*innen im Therapieprozess durch
              eine App zu unterstützen, die Therapieerfolg fördern soll. <br />
              <br /> Gründer des Projekts ist Jonas Herrmannsdörfer
              (IT-Student), der sich in seinem Praxissemester intensiv mit der
              Konzeption und Entwicklung der App auseinandersetzt. Unterstützung
              erhält er dabei von{" "}
              <OutsideLink href="https://elbstack.com/de/" input="elbstack" />,
              einer Entwicklungsagentur aus Hamburg. Zudem wird das Team durch
              einen Rechtsanwalt und einen Datenschutzexperten ergänzt. Ein ganz
              wesentlicher Bestandteil ist die aktive Zusammenarbeit mit
              verschiedenen Psychotherapeut*innen, die wertvolle Inputs aus der
              Praxis liefern. Letztendlich möchten wir eine App entwickeln, die
              optimal auf die Bedürfnisse von Therapeut*innen und Patient*innen
              passt. Dies kann nur in intensiven Austausch mit den
              Praktizierenden erfolgen. Deshalb schon einmal vielen Dank für die
              Unterstützung und das Interesse, das wir bereits erhalten haben.{" "}
              <br />
              <br /> Vielleicht haben Sie Fragen, Anregungen oder Kritik? Dann
              schreiben Sie mir gerne eine Mail (
              <OutsideLink
                type={LinkType.mail}
                href="jonas@diepsychotherapieapp.de"
              />
              ) oder nutzen Sie den Live Chat - ich freue mich auf Ihre
              Nachricht.
              <br />
              <br /> Viele Grüße, <br />
              Jonas Herrmannsdörfer
            </p>
            <img src={JonasImage} alt="" width="200" className="mt-2" />
          </>
        }
        elementRight={
          <SplitViewImage
            src={AppEntwicklungIllustration}
            alt="Bestandteile der App Entwicklung Illustration"
          />
        }
      />
      <Section background>
        <H2 text="Das Team" className="text-center" />
        <ThreeColumns input={teamInput} />
      </Section>
    </Layout>
  )
}

export default UeberUns
